import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/ra.fakel.com.ua/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "комопнент-resource"
    }}>{`Комопнент `}<inlineCode parentName="h1">{`<Resource>`}</inlineCode></h1>
    <p>{`Компонент `}<inlineCode parentName="p">{`Resource`}</inlineCode>{` - обязательный потомок для компонента `}<inlineCode parentName="p">{`<Admin>`}</inlineCode>{`
С его помощью создаюся компоненты `}<inlineCode parentName="p">{`view`}</inlineCode>{` для каждого ресурса.`}</p>
    <p>{`На базе `}<inlineCode parentName="p">{`name`}</inlineCode>{` и переданных компонентов `}<inlineCode parentName="p">{`view`}</inlineCode>{` создаться пути для маршрутизации, таким образом:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`name/list`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`name/create`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`name/edit`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`name/show`}</inlineCode></li>
    </ul>
    <p>{`Если какой-то есть компонентов `}<inlineCode parentName="p">{`view`}</inlineCode>{` не был передан - путь не создается`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`create (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React.ComponentType`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`list (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React.ComponentType`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`edit (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React.ComponentType`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`show (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React.ComponentType`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ResourceOptions`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h2>
    <pre><code parentName="pre" {...{}}>{` <Resource
    name="posts"
    list={PostsView}
    edit={PostEdit}
    create={PostsCreate}
    options={{
        label: 'Posts',
    }}
/>
`}</code></pre>
    <h2 {...{
      "id": "resource-options"
    }}>{`Resource Options`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React.ReactNode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`renderViewOnMenuClicked (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`linkUrl (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p"><inlineCode parentName="strong">{`renderViewOnMenuClicked`}</inlineCode></strong>{` - определяет какой `}<inlineCode parentName="p">{`view`}</inlineCode>{` нужно отобразить, при переходе на ссылку в меню.
По-умолчанию - `}<inlineCode parentName="p">{`list`}</inlineCode></p>
    <p><strong parentName="p"><inlineCode parentName="strong">{`icon`}</inlineCode></strong>{` - определяет иконку для ее отображения в меню.`}</p>
    <p><strong parentName="p"><inlineCode parentName="strong">{`label`}</inlineCode></strong>{` - определяет заголовок ссылки в меню`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      